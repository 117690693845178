<template>
  <div>
    <van-overlay class="pull-new" :show="redirectCode == 2">
      <Packet class="packet-components" :token="token" :version="version" :channel="channel"></Packet>
    </van-overlay>
  </div>
</template>
<script>
import Vue from 'vue'
import { Overlay } from 'vant'
import Packet from '@/components/packet'
import baseUrl from '@/utils/baseUrl'

const api = require('../../utils/api').api
Vue.use(Overlay)

export default {
  name: 'choosePage',
  data () {
    return {
      token: '',
      version: '',
      channel: '',
      redirectCode: ''
    }
  },
  components: {
    Packet
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    const u = window.navigator.userAgent
    // ios终端
    const isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // android
    const isAndroidApp = (u.indexOf('Android') > -1)
    this.$store.commit('edit',{isIOSApp:isIOSApp,isAndroidApp:isAndroidApp})
    this.initChoosePage()
  },
  methods: {
    // 前置判断
    initChoosePage () {
      this.$axios(api.taskChoose, {
        params: {}
      }).then(res => {
        this.redirectCode = res.data.data.redirectCode
        if (this.redirectCode == 1) {
          if (this.$store.state.isBack == true) {
            this.$h5AppLink('navIndex', {})
          } else {
            this.$store.commit('backToChoose',{isBack: false})
            this.$router.push({ name: 'helpPacket', query:{token:this.token,version:this.version,channel:this.channel,taskId:res.data.data.taskId} })
          }
        } else if (this.redirectCode == 2) {
        } else if (this.redirectCode == 3) {
          //
          if (this.$store.state.isBack == true) {
            this.$h5AppLink('navIndex', {})
          } else {
            this.$store.commit('backToChoose',{isBack: false})
            this.$router.push({ name: 'pullNew', query:{token:this.token,version:this.version,channel:this.channel,taskId:res.data.data.taskId} })
          }
        }
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/packet/choose`
      }
      this.$h5AppLink(event, param)
    }
  }
}
</script>
<style lang="less" scoped>
.pull-new{
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  .packet-components{
    padding: 10px 15px 20px 15px;
  }
}
</style>
