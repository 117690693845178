<template>
  <div class="pull-new-component">
    <div class="title">
      <img :class="isScale ? 'after' : ''"
           :src="`https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_${isScale ? 'title_2' : 'title'}.png`">
    </div>
    <div v-if="!isScale" class="packet">
      <label>
        <input type="checkbox"/>
        <div :class="[taskIndex == 1 ? 'active' : '' ,'packet-item']" @click="handleOpen(1)">
          <div class="font" v-if="!isOpen">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_cover.png">
            <img class="coins" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_coins.png">
          </div>
          <div v-else class="font">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_open.png">
            <div class="back-text" v-if="amount">
              <p>{{ taskIndex == 1 ? '超级红包' : '福利红包' }}</p>
              <p>{{ taskIndex == 1 ? amount : randomAmountOne }}</p>
            </div>
          </div>
          <div class="back">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_open.png">
            <div class="back-text" v-if="amount">
              <p>{{ taskIndex == 1 ? '超级红包' : '福利红包' }}</p>
              <p>{{ taskIndex == 1 ? amount : randomAmountOne }}</p>
            </div>
          </div>
        </div>
      </label>
      <label>
        <input type="checkbox"/>
        <div class="packet-item" @click="handleOpen(2)">
          <div class="font" v-if="!isOpen">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_cover.png">
            <img class="coins" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_coins.png">
          </div>
          <div v-else class="font">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_open.png">
            <div class="back-text" v-if="amount">
              <p>{{ taskIndex == 2 ? '超级红包' : '福利红包' }}</p>
              <p>{{ taskIndex == 2 ? amount : randomAmountOne }}</p>
            </div>
          </div>
          <div class="back">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_open.png">
            <div class="back-text">
              <p>{{ taskIndex == 2 ? '超级红包' : '福利红包' }}</p>
              <p>{{ taskIndex == 2 ? amount : randomAmountOne }}</p>
            </div>
          </div>
        </div>
      </label>
      <label>
        <input type="checkbox"/>
        <div class="packet-item" @click="handleOpen(3)">
          <div class="font" v-if="!isOpen">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_cover.png">
            <img class="coins" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_coins.png">
          </div>
          <div v-else class="font">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_open.png">
            <div class="back-text">
              <p>{{ taskIndex == 3 ? '超级红包' : '福利红包' }}</p>
              <p>{{ taskIndex == 3 ? amount : randomAmountOne }}</p>
            </div>
          </div>
          <div class="back">
            <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_open.png">
            <div class="back-text">
              <p>{{ taskIndex == 3 ? '超级红包' : '福利红包' }}</p>
              <p>{{ taskIndex == 3 ? amount : randomAmountOne }}</p>
            </div>
          </div>
        </div>
      </label>
      <!--      <div class="packet-item">-->
      <!--        <img class="small" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_cover.png">-->
      <!--        <img class="coins" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_coins.png" @click="isOpen = !isOpen">-->
      <!--      </div>-->
    </div>
    <div :class="[isScale ? 'opened' : '','packet-open']">
      <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_opened.png"/>
      <div class="task-num">
        <p>{{randomAmountOne}}</p>
        <p class="super-task">{{amount}}</p>
        <p>{{randomAmountTwo}}</p>
      </div>
    </div>
    <div class="after-open" v-if="isScale">
      <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_button.png" @click="handleNavIndex">
      <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_bottom.png">
    </div>
  </div>
</template>
<script>
const api = require('@/utils/api').api

export default {
  name: 'openPacket',
  data () {
    return {
      // 点开红包的动作
      isOpen: false,
      //延时动画开启
      isScale: false,
      showOpenPacket: false,
      taskIndex: '',
      randomAmountOne: '',
      randomAmountTwo: '',
      amount: '',
      taskId: ''
    }
  },
  props: {
    token: String,
    channel: String | Number,
    version: String | Number
  },
  methods: {
    // 领取红包
    handleOpen (value) {
      this.$axios(api.taskOpen, {
        params: {}
      }).then(res => {
        this.taskIndex = value
        this.isOpen = true
        this.amount = res.data.data.amount
        this.taskId = res.data.data.taskId
        this.randomAmountOne = Math.random() > 0.5 ? 39.9 : 19.9
        this.randomAmountTwo = this.randomAmountOne == 39.9 ? 19.9 : 39.9
        setTimeout(() => {
          this.isScale = true
        }, 600)
      }).catch(err => {
        console.info(err)
      })
    },
    // 跳转红包会场
    handleNavIndex () {
      this.$router.push({ name: 'pullNew', query:{token:this.token,version:this.version,channel:this.channel,taskId:this.taskId} })
    }
  }
}
</script>
<style lang="less">
.pull-new-component {
  .title {
    margin-top: 44px;
    text-align: center;

    img {
      width: 202px;
    }

    .after {
      margin-bottom: 9px;
      width: 334px;
    }
  }

  .packet {
    margin-top: 34px;
    display: flex;
    justify-content: space-between;


    label {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      display: block;
      width: 108px;
      cursor: pointer;
    }

    .packet-item {
      position: relative;
      height: 100%;
      width: 100%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: all 600ms;
      transition: all 600ms;
    }

    .packet-item div {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #FFF;
      text-align: center;
      //line-height: 200px;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 2px;
    }

    .packet-item .back {
      position: absolute;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }

    input {
      display: none;
    }

    :checked + .packet-item {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
    }

    label :checked + .packet-item {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      box-shadow: 0 20px 20px rgba(255, 255, 255, 0.2);
    }


    .packet-item {
      position: relative;
    }

    .small {
      width: 108px;
      background: none;
      //animation: swing .5s linear infinite;
      //transform: rotate(0deg);
    }

    .back-text {
      position: absolute;
      top: 70px;
      display: flex;
      flex-wrap: wrap;

      & > p {
        width: 108px;
        color: #FDDD6D;
        line-height: 25px;
      }
    }

    .coins {
      position: absolute;
      top: 62px;
      left: 22px;
      width: 64px;
    }

  }

  .packet-open{
    position: absolute;
    top: 72px;
    left: 0;
    display: none;
    & > img {
      width: 100%;
      height: 378px;
    }
    .task-num {
      position: absolute;
      top: 197px;
      left: 50px;
      width: 266px;
      display: flex;
      justify-content: space-between;
      & > p {
        opacity: 0.5;
        font-size: 15px;
        font-weight: 800;
        color: #FFE68E;
        line-height: 21px;
      }
      .super-task {
        position: relative;
        left: 6px;
        line-height: 56px;
        opacity: 1;
        font-size: 40px;
      }
    }
  }
  .opened{
    display: block;
    animation:mymove 0.6s;
    -webkit-animation:mymove 0.6s; /*Safari and Chrome*/
  }
  @keyframes mymove
  {
    0%{
      transform: scale(0);  /*开始为原始大小*/
    }
    100%{
      transform: scale(1); /*放大1.1倍*/
    }

  }

  @-webkit-keyframes mymove /*Safari and Chrome*/
  {
    0%{
      transform: scale(0);  /*开始为原始大小*/
    }
    100%{
      transform: scale(1); /*放大1.1倍*/
    }
  }

  .after-open {
    margin-top: 310px;
    text-align: center;

    & > img:first-child {
      width: 330px;
    }

    & > img:last-child {
      margin-top: 12px;
      width: 244px;
    }
  }
}

</style>
